@import "../../shared/assets/scss/variables.scss";

.chatSideContainer {
  height: 100%;
  position: relative;

  .chat-messages {
    &.height {
      height: 81.5%;
    }
    &.compose-modal {
      height: 81%;
    }
    &.reduce-height {
      height: 76%;
    }
    &.reduce-height-compose {
      height: 70%;
    }

    .infinite-scroll-component__outerdiv {
      margin-bottom: 0.2rem;
    }
  }
  #chat-messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 8px;
  }
  .compose-message-section {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 27px 32px 0px 28px;

    .center-align-loading-container {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .add-recipient-search-section {
      display: flex;
      flex-direction: row;
      height: 40px;
      border: 1px solid #a6d9ce;
      border-radius: 8px;

      .input-field-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px 5px 5px 12px;

        .input-fields {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          .add-recipient-input {
            border: none;
            font-size: 24px;
            outline: none;
            font-family: "Mon-medium", sans-serif;
            font-size: 15px;
            margin-left: 15.45px;
          }
        }

        .selected-dropdown-section {
          .selected-dropdown-button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            background: $light-green-color-2;
            border-radius: 4px;
            padding: 4px 7px 5px 13px;
            font-family: "Mon-medium", sans-serif;
            font-size: 15px;
            line-height: 22px;

            .down-arrow-icon {
              padding: 4px;
            }

            .up-arrow-icon {
              padding: 4px;
            }
          }

          .grey-background {
            background: $light-green-color-2;
          }
        }
      }
    }

    .selected-dropdown {
      position: absolute;
      width: 100%;
      top: 45px;
      max-height: 320px;
      overflow-y: auto;
      z-index: 2;
      background: #dbe7e5;
      border-radius: 12px;

      .dropdown-single-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 22px 24px;

        .detail-section {
          font-family: "Mon-medium", sans-serif;
          font-size: 14px;
        }
      }

      .dropdown-single-item:not(:last-child) {
        border-bottom: 1px solid #cad5d3;
      }
    }

    .search-results-section {
      position: absolute;
      top: 45px;
      width: 100%;
      max-height: 320px;
      overflow-y: auto;
      z-index: 2;
      background: #dbe7e5;
      border-radius: 12px;

      .empty-state-container {
        height: 61px;
      }
      .loader img {
        width: 5rem;
      }

      .search-items {
        display: flex;
        flex-direction: row;
        padding: 22px 24px;
        font-family: "Mon-medium", sans-serif;
        font-size: 14px;
        cursor: pointer;

        .highlighted-text-section {
          display: flex;
          flex-direction: row;
        }

        .bold-text {
          font-weight: bold;
          color: #1aa99c;
        }
      }

      .search-items:not(:last-child) {
        border-bottom: 1px solid #cad5d3;
      }
    }
  }

  .footer-container,
  .hidden-footer-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 2;

    .inactive-infobar-container {
      display: flex;
      padding: 4px 12px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      background: #70a39f;
      color: #fff;

      font-size: 12px;
      font-style: italic;
      line-height: 140%;

      .note-label {
        text-transform: uppercase;
        font-family: "Mon-bold", sans-serif;
        margin-right: 4px;
      }
      .note {
        font-family: "Mon-medium", sans-serif;
      }
    }

    .next-button {
      width: 90%;
      color: #fff;
      font-family: "Mon-medium", sans-serif;
      font-size: 1.6rem;
      border-radius: 0.8rem;
      margin: 0px 0px 3.9rem 2.5rem;
      padding: 10px 30px;

      &:disabled {
        cursor: not-allowed;
      }
    }

    .progress-bar {
      height: 3.4rem;
      width: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 2rem;

      .cancel-upload {
        position: absolute;
        svg {
          width: 1rem !important;
          height: 1rem !important;
        }
      }

      .retry-upload {
        position: absolute;
        svg {
          width: 1rem !important;
          height: 1rem !important;
        }
      }
    }

    .preview-section {
      top: 0;
      &.hidden {
        display: none;
        opacity: 0;
      }
      &.show {
        position: absolute;
        top: -10rem;
        display: flex;
        opacity: 1;
        width: 100%;
        background: #dbe7e5;
        align-items: center;
        margin-top: 5px;
      }
    }
  }

  .hidden-footer-container {
    bottom: 2px;
  }
}

.navigator-manager {
  .modal-body {
    .chat-messages {
      &.height {
        height: 81%;
      }
    }
  }
}

.mobileView {
  .chatSideContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .chat-messages {
      &.height {
        height: 100%;
      }
      &.compose-modal {
        height: 100%;
      }
      &.reduce-height {
        height: 100%;
      }
      &.reduce-height-compose {
        height: 100%;
      }
    }
    .compose-message-section {
      margin: 0 1.6rem;

      .add-recipient-search-section {
        .input-field-container {
          position: relative;

          .input-fields {
            .add-recipient-input {
              font-size: 1.6rem;
              padding: 0;
              &::placeholder {
                font-size: 1.4rem;
              }
            }
          }

          .selected-dropdown-section {
            position: absolute;
            right: 0;
            .selected-dropdown-button {
              font-size: 1.2rem;
            }
          }
        }
      }

      .search-results-section {
        max-height: calc(320px - 90px);
      }
    }

    .footer-container {
      position: static;
      display: flex;
      flex-direction: column;

      .progress-bar {
        margin: 2rem 1rem;
      }

      .preview-section {
        &.show {
          position: static;
          justify-content: space-between;
        }
      }
    }
  }
}
