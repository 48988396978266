.tag-component-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3.5px 8px 3.5px 8px;
  background: #ffffff;
  border: 1px solid #667a78;
  border-radius: 20px;
  font-family: "Mon-semi-bold", sans-serif;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: #667a78;
}
