#note-container {
  display: flex;
  background: #70a39f;
  padding: 0.4rem 1.2rem;
  column-gap: 1rem;

  &.note-listing {
    flex-direction: column;
    row-gap: 0.5rem;
    ul {
      list-style-type: disc;
      margin-left: 2rem;
    }
  }

  .title {
    font-family: "Mon-bold", sans-serif;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 140%;
    color: #fff;
  }

  .description-text {
    color: #fff;
    font-family: "Mon-medium", sans-serif;
    font-size: 1.2rem;
    font-style: italic;
    line-height: 140%;
  }
}
